import React from "react";
import team001 from "../../images/t3.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Dale Humphries</h3>
    <h4 className="">Director</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          Dale is a fourth-generation farmer still living and working on part of
          the original family farm “Wattle Grove” in Oberon.
        </p>
        <p>
          A passionate Speckle Park breeder with over 30 years experience in the
          beef cattle industry, Dale has spent the last 12 years solely focused
          on importing and producing Speckle Park.
        </p>
        <p>
          Dale has been involved with Speckle Park since its introduction to
          Australia in 2007
        </p>
        <p>
          He has been a driving force in creating the success of Speckle Park
          and through his involvement with Speckle Park International Inc and
          Speckle Park Group Ltd he is determined to see that success continue.{" "}
        </p>
        <p>
          Dale believes in trust, integrity, growth, consistency and that there
          is always a better way. As a founding member of SPKL by Speckle Park,
          Dale is focused on ensuring that all members of the supply chain are a
          part of the Speckle Park story and success.
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
